<template>
  <AdminLayout>
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i class="fas fa-clipboard-list me-2"></i>{{ menuTitles.transfers }}</h1>
      <ol class="breadcrumb mb-4">
        <router-link :to="{name: 'dashboard'}" class="breadcrumb-item"><i class="fas fa-house me-2"></i>Αρχική
        </router-link>
        <li class="breadcrumb-item active"><i class="fas fa-clipboard-list me-2"></i>{{ menuTitles.transfers }}</li>
      </ol>

      <div class="card mb-4">
        <div class="card-header">
          <div class="row">
            <div class="col float-start"><i class="fas fa-table me-1"></i>Πίνακας κινήσεων</div>
            <div class="col" v-if="this.privileges.canSeeTransfersInfo">
              <button class="btn btn-outline-dark float-end" @click="prepareGenerateReport"><i class="fas fa-file-arrow-down me-1"></i>Εξαγωγή</button>
            </div>
          </div>
          <div class="row">
            <div class="col float-start">
              <div class="d-none d-md-flex">
                Κινήσεις:
                <a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(0)"><i class="badge rounded-pill bg-black">Όλες</i></span></a>
                <a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(1)"><i class="badge rounded-pill bg-primary">Γέμισμα</i></span></a>
                <a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(2)"><i class="badge rounded-pill bg-success">Εμφιάλωση</i></span></a>
                <a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(3)"><i class="badge rounded-pill bg-info">Μεταφορά</i></span></a>
              </div>
              <div class="d-flex d-md-none">
                <a class="nav-link dropdown-toggle" id="typeOfMoveDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Κινήσεις</a>
                <ul class="dropdown-menu" aria-labelledby="typeOfMoveDropdown">
                  <li><a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(0)"><i class="badge rounded-pill bg-black">Όλες</i></span></a></li>
                  <li><a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(1)"><i class="badge rounded-pill bg-primary">Γέμισμα</i></span></a></li>
                  <li><a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(2)"><i class="badge rounded-pill bg-success">Εμφιάλωση</i></span></a></li>
                  <li><a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(3)"><i class="badge rounded-pill bg-info">Μεταφορά</i></span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table id="logs-datatable" class="table table-striped">
            <thead>
            <tr>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-glass-whiskey me-1" title="Προέλευση"></i>
                  <span class="d-none d-md-table-cell">Προέλευση</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-glass-whiskey me-1" title="Προορισμός"></i>
                  <span class="d-none d-md-table-cell">Προορισμός</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-right-left me-1" title="Κίνηση"></i>
                  <span class="d-none d-md-table-cell">Κίνηση</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-filter me-1" title="Ποσότητα"></i>
                  <span class="d-none d-md-table-cell">Ποσότητα</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-calendar me-1" title="Ημερομηνία"></i>
                  <span class="d-none d-md-table-cell">Ημερομηνία</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-gear me-1" title="Ενέργειες"></i>
                </div>
              </th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="tableInputs.container1"></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="tableInputs.container2"></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="tableInputs.type"></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="tableInputs.quantity"></th>
              <th>
                <VueDatePicker
                    ref="datepicker"
                    v-model="date"
                    locale="el"
                    cancelText="Ακύρωση"
                    selectText="Αναζήτηση"
                    placeholder="Ημερομηνία"
                    range
                    :enable-time-picker="false"
                    @update:model-value="handleDate"
                ></VueDatePicker>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-gear me-1" title="Ενέργειες"></i>
                </div>
              </th>
            </tr>
            </tfoot>
            <tbody>

            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="modal fade" ref="LogsModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" ref="modalTitle"><i class="fas fa-clipboard-list me-1"></i>Αναλυτικά στοιχεία αναφοράς</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>

              <div class="mb-3">
                <span class="me-2"><b>Κίνηση:</b></span>
                <span><i ref="formTypeOfMove"></i></span>
              </div>

              <div class="mb-3">
                <span class="me-2"><b>Ημερομηνία:</b></span>
                <span><i ref="formDate"></i></span>
              </div>

              <div class="mb-3">
                <span class="me-2"><b ref="formContainersTitle"></b></span>
                <span><i ref="formContainers"></i></span>
              </div>

              <div class="mb-3">
                <span class="me-2"><b>Ποσότητα:</b></span>
                <span><i ref="formQuantity"></i></span>
              </div>

              <div class="mb-3">
                <span class="me-2"><b>Σημειώσεις:</b></span>
                <span><i ref="formNotes"></i></span>
              </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
              Κλείσιμο
            </button>
            <div ref="modalButton"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" ref="ReportModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" ref="modalTitle"><i class="fas fa-clipboard-list me-1"></i>Αναλυτικά στοιχεία αναφοράς</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>

              <div class="mb-3">

              </div>

              <div class="mb-3">
                <VueDatePicker
                    v-model="report.date"
                    locale="el"
                    cancelText="Ακύρωση"
                    selectText="Επιλογή"
                    placeholder="Ημερομηνία"
                    range
                    :enable-time-picker="false"
                ></VueDatePicker>
              </div>

              <div class="mb-3">
                <span class="me-2"><b>Λεπτομέρειες:</b></span>
                <span><i ref="formDetails"></i></span>
              </div>

              <div class="mb-3">
                <span class="me-2"><b>Σημειώσεις:</b></span>
                <span><i ref="formNotes"></i></span>
              </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
              Κλείσιμο
            </button>
            <div ref="modalButton"></div>
          </div>
        </div>
      </div>
    </div>

  </AdminLayout>
</template>

<script>
// @ is an alias to /src

import AdminLayout from "@/layouts/AdminLayout.vue";
import $ from "jquery";
import {DatatableLanguages} from "@/assets/js/Library/Datatables";

import bootstrap from 'bootstrap/dist/js/bootstrap.min';

import 'datatables.net/js/jquery.dataTables.min';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-bs5/js/dataTables.bootstrap5.min.js';
import VueDatePicker from '@vuepic/vue-datepicker';
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import menuTitles from "../assets/js/Library/MenuTitles";
import {DatatableErrorHandle} from "@/assets/js/Library/Datatables";
import Privileges from "@/assets/js/Library/Privileges";

export default {
  name: 'ContainersView',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {AdminLayout, VueDatePicker},
  created() {
    window.info = this.info;
  },
  data() {
    return {
      privileges: {
        canSeeTransfersInfo: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_SEE_TRANSFERS_INFO) === Privileges.CAN_SEE_TRANSFERS_INFO,
        canSeeLogsInfo: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_SEE_LOGS_INFO) === Privileges.CAN_SEE_LOGS_INFO,
      },
      datatable: null,
      modalRef: null,
      reportModalRef: null,
      datePickerRef: null,
      date: null,
      tableInputs: {
        container1: '',
        container2: '',
        type: '',
        quantity: '',
      },
      report: {
        container: null,
        product: null,
        date: null,
        type: null,
      }
    }
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.LogsModal)
    this.reportModalRef = new bootstrap.Modal(this.$refs.ReportModal)

    this.datePickerRef = this.$refs.datepicker;

    this.datatable = $('#logs-datatable').DataTable({
      initComplete: function () {
        this.api()
            .columns()
            .every(function () {
              const that = this;

              $('input', this.footer()).on('keyup change clear', function () {
                if (that.search() !== this.value) {
                  that.search(this.value).draw();
                }
              });
            });
      },
      rowCallback: function (row, data) {
        if (data.container1_name === data.container2_name) {
          // If container1_name equals container2_name, expand the first column
          $(row).find('td:eq(0)').attr('colspan', 2);
          $(row).find('td:eq(1)').hide(); // Hide the second column
        }
      },
      responsive: true,
      language: DatatableLanguages,
      columnDefs: [
        {
          data: 'container1_name',
          render: (data, type, row) => this.getContainerFormatted(data, row.content_1),
          orderable: true,
          className: 'dt-center',
          targets: 0,
          width: '20%'
        },
        {data: 'container2_name', orderable: true, className: 'dt-center', targets: 1, width: '20%'},
        {
          data: 'transfer_type_name',
          render: (data, type, row) => this.getTypeOfMove(row.transfer_type_id, data, 'datatable'),
          orderable: true,
          className: 'dt-center',
          targets: 2,
          width: '15%'
        },
        {data: 'quantity', orderable: true, className: 'dt-center', targets: 3, width: '10%'},
        {
          data: 'created_at',
          render: (data, type, row) => this.getFormattedDate(row.created_date, row.created_time),
          orderable: true,
          className: 'd-none d-md-table-cell dt-center',
          targets: 4,
          width: '25%'
        },
        {
          data: null,
          render: (data) => this.getActionButtons(data.id),
          orderable: false,
          className: 'dt-center',
          targets: 5,
          width: '10%'
        }
      ],
      autoWidth: false,
      processing: true,
      serverSide: true,
      order: [[4, 'desc']],
      ajax: {
        type: 'POST',
        url: apiRoutes.transfers.get,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Accept: 'application/json'
        },
        error: (error) => DatatableErrorHandle(error)
      },
    });

    // this.datePickerRef.openMenu();
  },
  methods: {
    getActionButtons(id) {
      return `<button class="btn btn-sm btn-outline-dark" onclick="info(${id})"><i class="fas fa-info-circle"></i></button>`
    },
    getFormattedDate(date, time) {
      return `<i class="fas fa-calendar me-1"></i>${date} <i class="fas fa-clock ms-1 me-1"></i>${time}`;
    },
    getTypeOfMove(id, type, scope = null) {
      let color = '';
      if (id === 1) {
        color = 'primary';
      }
      if (id === 2) {
        color = 'success';
      }
      if (id === 3) {
        color = 'info';
      }

      let span = `<span class="badge rounded-pill bg-${color}">`;
      if (scope === 'datatable') {
        span += `<div class="d-sm-table-cell d-md-none">${this.sanitizeString(type.charAt(0))}</div>`;
      }
      span += `<div class="${scope === 'datatable' ? 'd-none d-md-table-cell' : ''}">${type}</div>`;
      span += `</span>`;

      return span;
    },
    getContainerFormatted(name, product) {
      return name + ` (${(product ? product : ' - ')})`;
    },
    sanitizeString(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    info(id) {
      if (!this.privileges.canSeeTransfersInfo) {
        return;
      }

      axios.post(apiRoutes.transfers.view, {id: id}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      }).then((response) => {
        this.$refs.formDate.innerHTML = `<i><i class="fas fa-regular fa-calendar ms-2"></i> ${response.data.created_date} <i class="fas fa-regular fa-clock ms-2"></i> ${response.data.created_time}</i>`;
        this.$refs.formTypeOfMove.innerHTML = this.getTypeOfMove(response.data.transfer_type_id, response.data.transfer_type_name);
        this.$refs.formContainersTitle.innerHTML = response.data.container1_name === response.data.container2_name ? 'Δεξαμενή:' : 'Δεξαμενές:';
        this.$refs.formContainers.innerHTML = response.data.container1_name === response.data.container2_name ? response.data.container1_name + ` (${response.data.content_1 ?? '-'})` : response.data.container1_name + ` (${response.data.content_1 ?? '-'}) <i class="fas fa-right-long mx-2"></i>` + response.data.container2_name;

        this.$refs.formQuantity.innerHTML = response.data.quantity + ' Λίτρα' + (parseInt(response.data.transfer_type_id) !== 1 ? ` (Φύρα: ${response.data.waste})` : '');
        this.$refs.formNotes.innerHTML = response.data.notes ? response.data.notes : '-' ;

        this.modalRef.show();
      })
    },
    handleDate(value) {
      if (value && this.datatable.search() !== value) {
        const formattedDate = [value[0].toLocaleDateString(), value[1].toLocaleDateString()]
        this.datatable.columns().column(4).search(formattedDate).draw();
        return;
      }
      this.datatable.columns().column(4).search('').draw();

    },
    filterTableByType(type) {
      let value = '';
      if (type === 1) {
        value = 'Γέμισμα'
      }
      if (type === 2) {
        value = 'Εμφιάλωση'
      }
      if (type === 3) {
        value = 'Μεταφορά'
      }

      this.tableInputs.type = value;
      this.datatable.columns().column(2).search(value).draw();
    },
    prepareGenerateReport() {
      this.reportModalRef.show();
    },
    generateReport() {}
  }
}
</script>
