<template>
  <AdminLayout>
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i class="fas fa-user-shield me-2"></i>{{ menuTitles.admins }}</h1>
      <ol class="breadcrumb mb-4">
        <router-link :to="{name: 'dashboard'}" class="breadcrumb-item"><i class="fas fa-house me-2"></i>Αρχική
        </router-link>
        <li class="breadcrumb-item active"><i class="fas fa-user-shield me-2"></i>{{ menuTitles.admins }}</li>
      </ol>

      <div class="card mb-4">
        <div class="card-header">
          <div class="row">
            <div class="col"><i class="fas fa-table me-1"></i>Πίνακας Διαχειριστών Συστήματος</div>
            <div class="col">
              <button @click="prepareStore" class="btn btn-outline-dark float-end"><i class="fas fa-plus me-2"></i>Προσθήκη
              </button>
            </div>
          </div>

        </div>
        <div class="card-body">
          <AdminsTable ref="adminsTable"/>
        </div>
      </div>
      <AdminsModal ref="adminsModal" :title="modal.title" :button="modal.button" @stored="store" @edited="edit"/>

    </div>

  </AdminLayout>
</template>

<script>
// @ is an alias to /src

import AdminLayout from "@/layouts/AdminLayout.vue";

import 'datatables.net/js/jquery.dataTables.min';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-bs5/js/dataTables.bootstrap5.min.js';
import menuTitles from "../../assets/js/Library/MenuTitles";
import AdminsTable from "@/views/Admins/AdminsTable.vue";
import AdminsModal from "@/views/Admins/AdminsModal.vue";
import {VueFinalModal} from "vue-final-modal";
import modals from "@/assets/js/Library/Modals";
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import Swal from "sweetalert2";
import Privileges from "@/assets/js/Library/Privileges";
import ModalButtons from "@/assets/js/Library/Modals";

export default {
  name: 'AdminsView',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {VueFinalModal, AdminsModal, AdminsTable, AdminLayout},
  created() {
    window.info = this.info;
  },
  data() {
    return {
      show: false,
      datatable: null,
      modal: {
        title: '',
        button: '',
      }
    }
  },
  mounted() {

  },
  methods: {
    prepareStore() {
      this.modal.title = 'Προσθήκη νέου διαχειριστή';
      this.modal.button = modals.create;

      this.$refs.adminsModal.clearForm();
      this.$refs.adminsModal.showPasswordFields();
      this.$refs.adminsModal.openModal();
    },
    store() {
      this.$refs.adminsTable.fetchData();
    },
    edit(id) {
      this.modal.title = 'Διόρθωση Στοιχείων Διαχειριστή'
      this.modal.button = ModalButtons.update(id)
    }
  }
}
</script>
