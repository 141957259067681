<template>
  <AdminLayout>
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i class="fas fa-champagne-glasses me-2"></i>{{ menuTitles.products }}</h1>
      <ol class="breadcrumb mb-4">
        <router-link :to="{name: 'dashboard'}" class="breadcrumb-item"><i class="fas fa-house me-2"></i>Αρχική
        </router-link>
        <li class="breadcrumb-item active"><i class="fas fa-champagne-glasses me-2"></i>{{ menuTitles.products }}</li>
      </ol>

      <div class="card mb-4">
        <div class="card-header">
          <div class="row">
            <div class="col float-start"><i class="fas fa-table me-1"></i>Πίνακας Διαχείρισης Προϊόντων</div>
            <div class="col" v-if="privileges.canStoreProducts">
              <button class="btn btn-outline-dark float-end" @click="prepareStore">
                <i class="fas fa-plus me-2"></i>Προσθήκη Νέου
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table id="products-datatable" class="table table-striped">
            <thead>
            <tr>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-quote-left me-1"></i>
                  <span class="d-none d-md-table-cell">Όνομα</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-warehouse me-1"></i>
                  <span class="d-none d-md-table-cell">Απόθεμα</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-faucet-drip me-1"></i>
                  <span class="d-none d-md-table-cell">Φύρα</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-gear me-1"></i>
                </div>
              </th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><i class="fas fa-gear me-1"></i></th>
            </tr>
            </tfoot>
            <tbody>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </AdminLayout>

  <div class="modal fade" ref="productsModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" ref="modalTitle"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>

            <div class="mb-3">
              <label for="product-name" class="form-label">Ονομασία</label>
              <input @keydown.enter.prevent="handleEnterKey" type="text" class="form-control" id="product-name"
                     v-model="form.name">
            </div>

            <div class="mb-3">
              <label for="product-liquids" class="form-label">Σύνθεση</label>
              <vue-select id="product-liquids"
                          :options="liquids"
                          :multiple="true"
                          :placeholder="'Επιλέξτε Συστατικά'"
                          v-model="form.liquids"
                          @option:selected="selectLiquid()"
                          @option:deselected="filterLiquids('')"
                          @keyup="filterLiquids($event.target.value)"
              >
                <template v-slot:no-options="{search}">
                          <span @click="$refs.select.open = false">
                              Δε βρέθηκαν αποτελέσματα για "{{ search }}"
                          </span>
                </template>
              </vue-select>
            </div>

            <div ref="productWaste" class="mb-3 d-none">
              <label for="product-waste" class="form-label">Φύρα</label>
              <input @keydown.enter.prevent="handleEnterKey" type="number" step=".01" class="form-control" id="product-waste"
                     v-model="form.waste">
            </div>

          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <div ref="modalButton"></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src

import AdminLayout from "@/layouts/AdminLayout.vue";

import 'datatables.net/js/jquery.dataTables.min';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-bs5/js/dataTables.bootstrap5.min.js';

import $ from "jquery";

import bootstrap from 'bootstrap/dist/js/bootstrap.min';

import {VueSelect} from "vue-select/src";

import {DatatableLanguages} from "@/assets/js/Library/Datatables";
import ModalButtons from "@/assets/js/Library/Modals";
import axios from "axios";
import Swal from "sweetalert2";
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import menuTitles from "../assets/js/Library/MenuTitles";
import apiRoutes from "@/router/ApiRoutes";
import errorHandle from "@/assets/js/Library/ErrorHandle";
import {DatatableErrorHandle} from "@/assets/js/Library/Datatables";
import Privileges from "@/assets/js/Library/Privileges";

export default {
  name: 'ProductsView',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {AdminLayout, VueSelect},
  created() {
    window.store = this.store;
    window.edit = this.edit;
    window.update = this.update;
    window.destroy = this.destroy;
  },
  data() {
    return {
      privileges: {
        canStoreProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_STORE_PRODUCTS) === Privileges.CAN_STORE_PRODUCTS,
        canEditProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_EDIT_PRODUCTS) === Privileges.CAN_EDIT_PRODUCTS,
        canUpdateProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_UPDATE_PRODUCTS) === Privileges.CAN_UPDATE_PRODUCTS,
        canDestroyProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_DESTROY_PRODUCTS) === Privileges.CAN_DESTROY_PRODUCTS,
        canFilterLiquids: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_FILTER_LIQUIDS) === Privileges.CAN_FILTER_LIQUIDS,
      },
      datatable: null,
      modalRef: null,
      liquids: [],
      form: {
        name: '',
        liquids: [],
        waste: 0
      }
    }
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.productsModal, {
      backdrop: 'static',
      keyboard: false
    })

    this.datatable = $('#products-datatable').DataTable({
      initComplete: function () {
        this.api()
            .columns()
            .every(function () {
              const that = this;

              $('input', this.footer()).on('keyup change clear', function () {
                if (that.search() !== this.value) {
                  that.search(this.value).draw();
                }
              });
            });
      },
      language: DatatableLanguages,
      columnDefs: [
        {data: 'name', orderable: true, className: 'dt-center', targets: 0, width: 'auto'},
        {data: 'total', orderable: true, className: 'dt-center', targets: 1, width: 'auto'},
        {data: 'waste', orderable: true, className: 'dt-center', targets: 2, width: 'auto'},
        {
          data: null,
          render: (data) => this.getActionButtons(data.id),
          orderable: false,
          className: `${this.privileges.canEditProducts || this.privileges.canUpdateProducts || this.privileges.canDestroyProducts ? 'dt-center' : 'd-none'}`,
          targets: 3,
          width: 'auto'
        }
      ],
      autoWidth: false,
      processing: true,
      serverSide: true,
      ajax: {
        type: 'POST',
        url: apiRoutes.products.datatable,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Accept: 'application/json'
        },
        error: (error) => DatatableErrorHandle(error)
      },
    });
  },
  methods: {
    getActionButtons(id) {
      let dropdown = `<a data-id="${id}" class="nav-link dropdown-toggle" id="actions-dropdown-${id}" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-cogs fa-fw"></i></a>`;
      dropdown += `<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="actions-dropdown-${id}">`;
      if (this.privileges.canEditProducts && this.privileges.canUpdateProducts) {
        dropdown += `<li onclick="edit(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-edit"></i> Διόρθωση</a></li>`;
      }
      if (this.privileges.canDestroyProducts) {
        dropdown += `<li><hr class="dropdown-divider" /></li>`;
        dropdown += `<li onclick="destroy(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-trash"></i> Διαγραφή</a></li>`;
      }
      dropdown += `</ul>`;
      return dropdown;
    },
    prepareStore() {
      this.$refs.modalTitle.innerHTML = '<i class="fas fa-champagne-glasses me-1"></i> Δημιουργία Νέου Προϊόντος'
      this.$refs.productWaste.classList.add('d-none');

      this.$refs.modalButton.innerHTML = ModalButtons.create

      this.clearForm();

      this.filterLiquids('');

      this.modalRef.show();
    },
    store() {
      if (!this.privileges.canStoreProducts) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      const productData = new FormData();
      productData.append('name', this.form.name);
      if (this.form.liquids.length > 0) {
        this.form.liquids.forEach((liquid, index) => {
          productData.append(`liquids[${index}][id]`, parseInt(liquid.value))
        })
      }

      axios.post(apiRoutes.products.store, productData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          });
        }
        this.datatable.ajax.reload(null, false)
        this.modalRef.hide();
      }).catch((error) => ErrorHandle(error));
    },
    edit(id) {
      if (!this.privileges.canEditProducts) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      this.$refs.modalTitle.innerHTML = '<i class="fas fa-edit me-1"></i> Διόρθωση Στοιχείων Προϊόντος'
      this.$refs.productWaste.classList.remove('d-none');
      this.$refs.modalButton.innerHTML = ModalButtons.update(id)

      this.filterLiquids('');

      axios.post(apiRoutes.products.edit, {id: id}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {

        if (response.status === 200) {

          this.clearForm();
          this.form.name = response.data.name;

          let liquidsIds = this.explode(',', response.data.liquids);
          let liquidsNames = this.explode(',', response.data.liquid_names);

          liquidsIds.forEach((item, index) => {
            this.form.liquids.push({value: item, label: liquidsNames[index]})
          })

          this.form.waste = response.data.waste;

          this.modalRef.show();

        }
      }).catch((error) => ErrorHandle(error));
    },
    update(id) {
      if (!this.privileges.canUpdateProducts) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      const productData = {
        id: parseInt(id),
        name: this.form.name,
        waste: this.form.waste
      };

      if (this.form.liquids.length > 0) {
        productData.liquids = [];

        this.form.liquids.forEach((liquid) => {
          productData.liquids.push({'id': parseInt(liquid.value)})
        })
      }

      axios.patch(apiRoutes.products.update, productData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          });
        }
        this.datatable.ajax.reload(null, false)
        this.modalRef.hide();
      }).catch((error) => ErrorHandle(error));
    },
    destroy(id) {
      if (!this.privileges.canDestroyProducts) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.fire({
        title: 'Προσοχή',
        icon: 'warning',
        text: 'Αυτή η ενέργεια θα διαγράψει μόνιμα την εγγραφή! Θέλετε σίγουρα να προχωρήσετε;',
        confirmButtonText: 'Διαγραφή',
        confirmButtonColor: 'red',
        showCancelButton: true,
        cancelButtonText: 'Ακύρωση',
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'DELETE',
            url: apiRoutes.products.destroy,
            data: {'id': id},
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
          }).then((response) => {
            Swal.fire({
              title: 'Επιτυχία',
              icon: 'success',
              text: response.data.message,
              allowEnterKey: true,
            })
            this.datatable.ajax.reload(null, false);
          }).catch(error => ErrorHandle(error))
        }
      });
    },
    clearForm() {
      this.form.name = '';
      this.form.liquids = [];
    },
    filterLiquids(value) {
      if (!this.privileges.canFilterLiquids) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      axios.post(apiRoutes.liquids.filter, {
        'name': value,
        'used_liquids': this.form.liquids
      }, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        this.liquids = [];
        response.data.forEach((item) => {
          this.liquids.push({value: item.id, label: item.name})
        })
      }).catch((error) => errorHandle(error));
    },
    explode(separator, str) {
      return str.split(separator);
    },
    selectLiquid() {
      this.filterLiquids('');
    },
    handleEnterKey() {
      // Use nextTick to ensure the DOM is updated
      this.$nextTick(() => {
        // Access the button using $refs after the DOM update
        document.getElementById('modal-confirm-button').click();
      });
    }
  }
}
</script>
