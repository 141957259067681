<template>
  <AdminLayout>
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i class="fas fa-glass-whiskey me-2"></i>{{ menuTitles.containers }}</h1>
      <ol class="breadcrumb mb-4">
        <router-link :to="{name: 'dashboard'}" class="breadcrumb-item"><i class="fas fa-house me-2"></i>Αρχική
        </router-link>
        <li class="breadcrumb-item active"><i class="fas fa-glass-whiskey me-2"></i>{{ menuTitles.containers }}</li>
      </ol>

      <div class="card mb-4">
        <div class="card-header">
          <div class="row">
            <div class="col-8 float-start"><i class="fas fa-table me-1"></i>Διαχείριση Δεξαμενών</div>
            <div class="col-4" v-if="privileges.canStoreContainers">
              <button class="btn btn-outline-dark float-end d-none d-md-block" @click="prepareStore"><i class="fas fa-plus me-2"></i>Προσθήκη Νέας</button>
              <button class="btn btn-outline-dark float-end d-md-none" title="Προσθήκη Νέας" @click="prepareStore"><i class="fas fa-plus"></i></button>
            </div>
          </div>
        </div>
        <div class="card-body">

          <table id="containers-datatable" class="table table-striped d-none d-lg-table">
            <thead>
            <tr>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-hashtag me-1"></i>
                  <span class="d-none d-md-table-cell">Κωδικός</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-wine-bottle me-1"></i>
                  <span class="d-none d-md-table-cell">Προϊόν</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-filter me-1" title="Τρέχουσα Ποσότητα"></i>
                  <i class="fas fa-wine-bottle me-1"></i>
                  <span class="d-none d-md-table-cell">Τρέχουσα Ποσότητα</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-filter me-1" title="Συν. Χωρητικότητα"></i>
                  <span class="d-none d-md-table-cell">Συν. Χωρητικότητα</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-fill me-1" title="Πληρότητα"></i>
                  <span class="d-none d-md-table-cell">Πληρότητα</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-gear me-1" title="Ενέργειες"></i>
                </div>
              </th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><i class="fas fa-gear me-1"></i></th>
            </tr>
            </tfoot>
            <tbody>

            </tbody>
          </table>

          <div class="d-lg-none row justify-content-center">
            <div class="col-6 col-sm-6 col-md-3" v-for="container in containersData" :key="container.id" @click="clickMobileContainer(container.id, container)">
              <div class="card custom-card text-center mx-2 my-2 position-relative">
                <div class="card-body">
                  <h5 class="card-title">{{ container.name }}</h5>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div class="fw-bold">Χωρητικότητα</div>
                    {{ container.capacity }} Λίτρα
                  </li>
                  <li class="list-group-item">
                    <div class="fw-bold">Προϊόν</div>
                    {{ container.product_name }}
                  </li>
                  <li class="list-group-item">
                    <div class="fw-bold">Επίπεδο</div>
                    <div v-html="getCapacityBar(container.overview)"></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="modal fade" ref="containersModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" ref="modalTitle"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="mb-3 d-none" ref="inputName">
                <label for="container-name" class="form-label">Κωδικός Δεξαμενής</label>
                <input @keydown.enter.prevent="handleEnterKey" type="text" class="form-control" id="container-name" v-model="form.name">
              </div>
              <div class="row d-none" ref="inputCapacity">
                <div class="col">
                  <div class="mb-3">
                    <label for="container-capacity" class="form-label">Χωρητικότητα</label>
                    <input @keydown.enter.prevent="handleEnterKey" type="text" class="form-control" id="container-capacity" min="0"
                           step=".01" @keyup="validateDecimal($event.target)"
                           v-model="form.capacity">
                  </div>
                </div>
              </div>

              <div class="row d-none" ref="inputContainers">
                <div class="col">
                  <div class="mb-3">
                    <label for="container-liquid" ref="inputContainersTitle">Προς Δεξαμενή</label>
                    <vue-select id="container-liquid"
                                :options="containers"
                                v-model="form.container_id"
                                @option:selected="selectContainer('transfer')"
                                @option:deselected="filterTransferContainers('')"
                                @keyup="filterTransferContainers($event.target.value)"
                    >
                      <template v-slot:no-options="{search}">
                          <span @click="$refs.select.open = false">
                              Δε βρέθηκαν αποτελέσματα για "{{ search }}"
                          </span>
                      </template>
                    </vue-select>
                  </div>
                </div>
              </div>

              <div class="row d-none" ref="inputQuantity">
                <div class="col">
                  <div class="mb-3">
                    <label for="container-liquid" ref="quantityTitleRef">Ποσότητα</label>
                    <input @keydown.enter.prevent="handleEnterKey" type="number" id="container-liquid" step=".01" class="form-control"
                           value="0" v-model="form.quantity">
                  </div>
                </div>
              </div>

              <div class="row d-none" ref="inputWaste">
                <div class="col">
                  <div class="mb-3">
                    <label for="container-liquid">Φύρα</label>
                    <input @keydown.enter.prevent="handleEnterKey" type="number" id="container-waste" step=".01" class="form-control"
                           value="0" v-model="form.waste">
                  </div>
                </div>
              </div>

              <div class="row d-none" ref="inputContainersBlend">
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                      <label for="blended-containers">Δεξαμενές</label>
                      <vue-select id="blended-containers" multiple
                                  :options="containers"
                                  v-model="form.containers"
                                  @option:selected="selectContainer('blend')"
                                  @option:deselected="filterBlendContainers('')"
                                  @keyup="filterBlendContainers($event.target.value)"
                      >
                        <template v-slot:no-options="{search}">
                          <span @click="$refs.select.open = false">
                              Δε βρέθηκαν αποτελέσματα για "{{ search }}"
                          </span>
                        </template>
                      </vue-select>
                    </div>
                  </div>
                </div>

                <div class="row" v-for="(container, index) in form.containers" :key="index">

                  <div class="col-9">
                    <div class="mb-3">
                      <label :for="`blended-product-quantity-${index}`">Ποσότητα από: <i><b>{{ container.name }}</b></i></label>
                      <input @keydown.enter.prevent="handleEnterKey" type="number" :id="`blended-product-quantity-${index}`" step=".01"
                             class="form-control" v-model="form.containers[index].quantity">
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="mb-3">
                      <label :for="`blended-product-waste-${index}`">Φύρα</label>
                      <input @keydown.enter.prevent="handleEnterKey" type="number" :id="`blended-product-waste-${index}`" step=".01"
                             class="form-control" v-model="form.containers[index].waste" value="0">
                    </div>
                  </div>

                </div>
              </div>

              <div class="row d-none" ref="inputProducts">
                <div class="col">
                  <div class="mb-3">
                    <label for="container-liquid">Προϊόν</label>
                    <vue-select id="container-liquid"
                                :options="products"
                                v-model="form.product_id"
                                @option:selected="selectProduct"
                                @keyup="filterProducts($event.target.value)"
                    >
                      <template v-slot:no-options="{search}">
                          <span @click="$refs.select.open = false">
                              Δε βρέθηκαν αποτελέσματα για "{{ search }}"
                          </span>
                      </template>
                    </vue-select>
                  </div>
                </div>
              </div>

              <div class="row d-none" ref="inputNotes">
                <div class="col">
                  <div class="mb-3">
                    <label for="container-notes">Σημειώσεις</label>
                    <textarea @keydown.enter.prevent="handleEnterKey" class="form-control" id="container-notes"
                              v-model="form.notes"></textarea>
                  </div>
                </div>
              </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
              Ακύρωση
            </button>
            <div ref="modalButton"></div>
          </div>
        </div>
      </div>
    </div>

  </AdminLayout>
</template>

<script>
// @ is an alias to /src

import AdminLayout from "@/layouts/AdminLayout.vue";
import axios from "axios";

import $ from 'jquery';
import 'jquery';

import bootstrap from 'bootstrap/dist/js/bootstrap.min';

import 'datatables.net/js/jquery.dataTables.min';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-bs5/js/dataTables.bootstrap5.min.js';

import {VueSelect} from "vue-select/src";
import Swal from 'sweetalert2'

import {DatatableLanguages} from "@/assets/js/Library/Datatables";
import ModalButtons from "@/assets/js/Library/Modals";
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import ApiRoutes from "@/router/ApiRoutes";
import menuTitles from "../../assets/js/Library/MenuTitles";
import apiRoutes from "@/router/ApiRoutes";
import {DatatableErrorHandle} from "@/assets/js/Library/Datatables";
import Privileges from "@/assets/js/Library/Privileges";

export default {
  name: 'ContainersView',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {AdminLayout, VueSelect},
  data() {
    return {
      containersData: [],
      privileges: {
        canStoreContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_STORE_CONTAINERS) === Privileges.CAN_STORE_CONTAINERS,
        canEditContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_EDIT_CONTAINERS) === Privileges.CAN_EDIT_CONTAINERS,
        canUpdateContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_UPDATE_CONTAINERS) === Privileges.CAN_UPDATE_CONTAINERS,
        canDestroyContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_DESTROY_CONTAINERS) === Privileges.CAN_DESTROY_CONTAINERS,
        canFilterProducts: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_FILTER_PRODUCTS) === Privileges.CAN_FILTER_PRODUCTS,
        canFilterContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_FILTER_CONTAINERS) === Privileges.CAN_FILTER_CONTAINERS,
        canAddProductToContainer: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_ADD_PRODUCT_TO_CONTAINER) === Privileges.CAN_ADD_PRODUCT_TO_CONTAINER,
        canTransferProductBetweenContainers: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_TRANSFER_PRODUCT_BETWEEN_CONTAINERS) === Privileges.CAN_TRANSFER_PRODUCT_BETWEEN_CONTAINERS,
        canBottleProduct: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_BOTTLE_PRODUCT) === Privileges.CAN_BOTTLE_PRODUCT,
        canBlendProduct: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_BLEND_PRODUCT) === Privileges.CAN_BLEND_PRODUCT,
      },
      products: [],
      containers: [],
      table: null,
      modalRef: null,
      form: {
        name: '',
        capacity: 0,
        quantity: 0,
        waste: 0,
        notes: '',
        product_id: null,
        container: null,
        container_id: null,
        containers: [],
      }
    }
  },
  created() {
    window.prepareAdd = this.prepareAdd;
    window.add = this.add;
    window.prepareBottling = this.prepareBottling;
    window.bottling = this.bottling;
    window.store = this.store;
    window.prepareTransfer = this.prepareTransfer;
    window.transfer = this.transfer;
    window.prepareBlend = this.prepareBlend;
    window.blend = this.blend;
    window.edit = this.edit;
    window.update = this.update;
    window.destroy = this.destroy;
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.containersModal, {
      backdrop: 'static',
      keyboard: false
    })

    this.table = $('#containers-datatable').DataTable({
      initComplete: function () {
        this.api()
            .columns()
            .every(function () {
              const that = this;

              $('input', this.footer()).on('keyup change clear', function () {
                if (that.search() !== this.value) {
                  that.search(this.value).draw();
                }
              });
            });
      },
      responsive: true,
      language: DatatableLanguages,
      columnDefs: [
        {data: 'name', orderable: true, className: 'dt-center', targets: 0, width: 'auto'},
        {
          data: 'product_name',
          orderable: true,
          className: 'd-none d-md-table-cell dt-center',
          targets: 1,
          width: 'auto'
        },
        {
          data: 'level',
          orderable: true,
          className: 'd-none d-md-table-cell dt-center',
          targets: 2,
          width: 'auto'
        },
        {data: 'capacity', orderable: true, className: 'dt-center', targets: 3, width: 'auto'},
        {
          data: 'overview',
          render: (data) => this.getCapacityBar(data),
          orderable: true,
          className: 'dt-center',
          targets: 4,
          width: 'auto'
        },
        {
          data: null,
          render: (data, type, row) => this.getActionButtons(data.id, row),
          orderable: false,
          className: `${this.privileges.canAddProductToContainer || this.privileges.canTransferProductBetweenContainers || this.privileges.canBottleProduct || this.privileges.canBlendProduct || this.privileges.canEditContainers || this.privileges.canUpdateContainers || this.privileges.canDestroyContainers ? 'dt-center' : 'd-none'}`,
          targets: 5,
          width: 'auto'
        }
      ],
      autoWidth: false,
      processing: true,
      serverSide: true,
      ajax: {
        type: 'POST',
        url: ApiRoutes.containers.datatable,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        complete: (data) => {
          this.containersData = data.responseJSON.data;
        },
        error: (error) => DatatableErrorHandle(error)
      },
    });

  },
  methods: {
    getActionButtons(id, row, mobile=false) {
      let dropdown = `<a data-id="${id}" class="nav-link dropdown-toggle" id="actions-dropdown-${id}" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-cogs fa-fw"></i></a>`;
      let mobileDropdown = '';

      dropdown += `<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="actions-dropdown-${id}">`;
      if (row.level < row.capacity) {
        dropdown += `<li onclick="prepareAdd(${id}, ${row.level}, ${row.capacity}, ${row.product_id}, '${row.product_name}')"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-droplet me-1 ms-1"></i> Γέμισμα</a></li>`;
        dropdown += `<li onclick="prepareBlend(${id}, '${row.name}', ${row.product_id}, ${row.level})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-shuffle me-1"></i> Σύμμιξη</a></li>`;

        mobileDropdown += `<button type="button" class="btn btn-outline-dark m-1" onclick="prepareAdd(${id}, ${row.level}, ${row.capacity}, ${row.product_id}, '${row.product_name}')"><i class="fas fa-droplet me-1"></i>Γέμισμα</button>`;
        mobileDropdown += `<button type="button" class="btn btn-outline-dark m-1" onclick="prepareBlend(${id}, '${row.name}', ${row.product_id}, ${row.level})"><i class="fas fa-shuffle me-1"></i>Σύμμιξη</button>`;
      }
      if (row.product_id && row.level > 0) {
        dropdown += `<li onclick="prepareBottling(${id}, ${row.level}, ${row.capacity})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-bottle-droplet me-1 ms-1"></i> Εμφιάλωση</a></li>`;
        mobileDropdown += `<button type="button" class="btn btn-outline-dark m-1" onclick="prepareBottling(${id}, ${row.level}, ${row.capacity})"><i class="fas fa-bottle-droplet me-1"></i>Εμφιάλωση</button>`;
        if (row.available_for_send > 0) {
          dropdown += `<li onclick="prepareTransfer(${id}, ${row.product_id}, ${row.level}, ${row.capacity})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-arrow-turn-up"></i> Μεταφορά Προϊόντος</a></li>`;
          mobileDropdown += `<button type="button" class="btn btn-outline-dark m-1" onclick="prepareTransfer(${id}, ${row.product_id}, ${row.level}, ${row.capacity})"><i class="fas fa-arrow-turn-up me-1"></i>Μεταφορά Προϊόντος</button>`;
        }
      }
      if (this.privileges.canEditContainers) {
        dropdown += `<li onclick="edit(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-edit"></i> Διόρθωση</a></li>`;
        mobileDropdown += `<button type="button" class="btn btn-outline-dark m-1" onclick="edit(${id})"><i class="fas fa-edit me-1"></i>Διόρθωση</button>`;
      }
      if (this.privileges.canDestroyContainers) {
        dropdown += `<li><hr class="dropdown-divider" /></li>`;
        dropdown += `<li onclick="destroy(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-trash"></i> Διαγραφή</a></li>`;
        mobileDropdown += `<button type="button" class="btn btn-outline-dark m-1" onclick="edit(${id})"><i class="fas fa-trash me-1"></i>Διαγραφή</button>`;
      }
      dropdown += `</ul>`;

      if (mobile) {
        return mobileDropdown;
      }

      return dropdown;
    },
    getCapacityBar(percentage) {
      let color;
      if (percentage >= 0 && percentage < 25) {
        color = 'bg-success';
      }
      if (percentage >= 25 && percentage < 50) {
        color = 'bg-info';
      }
      if (percentage >= 50 && percentage < 75) {
        color = 'bg-warning';
      }
      if (percentage >= 75 && percentage <= 100) {
        color = 'bg-danger';
      }
      return `<div class="progress" title="${percentage}%"><div class="progress-bar ${color}" role="progressbar" style="width: ${percentage}%;" aria-valuenow="${percentage}" aria-valuemin="0" aria-valuemax="100">${percentage}%</div></div>`;
    },
    prepareStore() {
      if (!this.privileges.canStoreContainers) {
        ErrorHandle({error: {response: {status: 401}}});
        return
      }
      this.resetModalInputs();

      this.$refs.modalTitle.innerHTML = '<i class="fas fa-whiskey-glass me-1"></i> Δημιουργία Νέας Δεξαμενής'
      this.$refs.inputName.classList.remove('d-none')
      this.$refs.inputCapacity.classList.remove('d-none')

      this.$refs.modalButton.innerHTML = ModalButtons.create

      this.clearForm();

      this.filterProducts('');

      this.modalRef.show();
    },
    store() {
      if (!this.privileges.canStoreContainers) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      const containerData = new FormData();
      containerData.append('name', this.form.name);
      containerData.append('capacity', this.form.capacity);
      if (this.form.product_id && this.form.product_id.value) {
        containerData.append('product_id', this.form.product_id.value);
      }

      axios.post(apiRoutes.containers.store, containerData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          });
        }
        this.table.ajax.reload(null, false)
        this.modalRef.hide();
      }).catch((error) => ErrorHandle(error));
    },
    prepareAdd(id, current, max, product_id, product_name) {
      if (!this.privileges.canAddProductToContainer) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.close();
      this.resetModalInputs();

      this.$refs.modalTitle.innerHTML = '<i class="fas fa-droplet me-1"></i> Γέμισμα Δεξαμενής'
      this.$refs.inputQuantity.classList.remove('d-none')
      this.$refs.inputNotes.classList.remove('d-none')
      this.$refs.quantityTitleRef.innerHTML = `Ποσότητα (${current} / ${max})` + (product_name !== '-' ? ` (${product_name})` : '')
      this.$refs.modalButton.innerHTML = ModalButtons.add(id)

      this.clearForm();

      if (product_id !== null) {
        this.form.product_id = {value: product_id, label: product_name};
      } else {
        this.$refs.inputProducts.classList.remove('d-none')
        this.filterProducts('');
      }

      this.modalRef.show();
    },
    add(id) {
      if (!this.privileges.canAddProductToContainer) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      const data = new FormData();
      data.append('container_id', id);
      data.append('product_id', this.form.product_id?.value);
      data.append('quantity', this.form.quantity);
      data.append('notes', this.form.notes);

      axios.post(apiRoutes.actions.add, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          })
          this.table.ajax.reload(null, false)
          this.modalRef.hide();
        }
      }).catch((error) => ErrorHandle(error));
    },
    prepareBottling(id, current, max) {
      if (!this.privileges.canBottleProduct) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.close();
      this.resetModalInputs();

      this.$refs.modalTitle.innerHTML = '<i class="fas fa-bottle-droplet me-1"></i> Εμφιάλωση'
      this.$refs.inputQuantity.classList.remove('d-none')
      this.$refs.inputNotes.classList.remove('d-none')
      this.$refs.inputWaste.classList.remove('d-none')
      this.$refs.quantityTitleRef.innerHTML = `Ποσότητα (${current} / ${max})`
      this.$refs.modalButton.innerHTML = ModalButtons.bottling(id)

      this.clearForm();

      this.modalRef.show();
    },
    bottling(id) {
      if (!this.privileges.canBottleProduct) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }

      const data = new FormData();
      data.append('container_id', id);
      data.append('quantity', this.form.quantity);
      data.append('waste', this.form.waste);
      data.append('notes', this.form.notes);

      axios.post(apiRoutes.actions.bottling, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          })
          this.table.ajax.reload(null, false)
          this.modalRef.hide();
        }
      }).catch((error) => ErrorHandle(error));
    },
    prepareTransfer(container_id, product_id, current, max) {
      if (!this.privileges.canTransferProductBetweenContainers) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.close();
      this.resetModalInputs();
      this.$refs.modalTitle.innerHTML = '<i class="fas fa-arrow-turn-up me-1"></i> Μεταφορά προϊόντος σε άλλη δεξαμενή'
      this.$refs.inputContainersTitle.innerHTML = 'Προς Δεξαμενή'
      this.$refs.modalButton.innerHTML = ModalButtons.transfer(container_id, 'send')

      this.$refs.inputContainers.classList.remove('d-none')
      this.$refs.inputQuantity.classList.remove('d-none')
      this.$refs.inputWaste.classList.remove('d-none')
      this.$refs.inputNotes.classList.remove('d-none')
      this.$refs.quantityTitleRef.innerHTML = `Ποσότητα τρέχουσας δεξαμενής (${current} / ${max})`
      this.clearForm();

      this.form.container = container_id;
      this.form.product_id = product_id;
      this.filterTransferContainers('');

      this.modalRef.show();
    },
    transfer(id) {
      if (!this.privileges.canTransferProductBetweenContainers) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      const data = new FormData();
      data.append('container1_id', id);
      data.append('container2_id', this.form.container_id.value);
      data.append('quantity', this.form.quantity);
      data.append('waste', this.form.waste);
      data.append('notes', this.form.notes);

      axios.post(apiRoutes.actions.transfer, data, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          });
          this.table.ajax.reload(null, false)
          this.modalRef.hide();
        }
      }).catch((error) => ErrorHandle(error))
    },
    prepareBlend(container_id, container_name, product_id, level) {
      if (!this.privileges.canBlendProduct) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.close();
      this.resetModalInputs();
      this.$refs.modalTitle.innerHTML = `<i class="fas fa-shuffle me-1"></i> Σύμμιξη προϊόντων στη δεξαμενή <b><i>${container_name}</i></b>`
      this.$refs.inputContainersTitle.innerHTML = 'Προς Δεξαμενή'
      this.$refs.modalButton.innerHTML = ModalButtons.blend(container_id, product_id, level)

      this.$refs.inputContainersBlend.classList.remove('d-none')
      this.$refs.inputNotes.classList.remove('d-none')
      this.clearForm();

      this.form.container_id = container_id;
      this.form.product_id = product_id;

      this.filterBlendContainers('');

      this.modalRef.show();
    },
    blend(container_id, product_id, level) {
      if (!this.privileges.canBlendProduct) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      let data = new FormData();
      data.append('container_id', container_id);
      data.append('notes', this.form.notes);
      if (product_id) {
        data.append(`containers[0][id]`, container_id)
        data.append(`containers[0][quantity]`, level)
        data.append(`containers[0][waste]`, 0)
      }
      this.form.containers.forEach((container, index) => {
        let indexPlace = index
        if (product_id) {
          indexPlace = (index + 1)
        }
        data.append(`containers[${indexPlace}][id]`, container.value)
        data.append(`containers[${indexPlace}][quantity]`, container.quantity)
        data.append(`containers[${indexPlace}][waste]`, container.waste)
      })

      axios.post(apiRoutes.actions.blend, data, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).then((response) => {
        Swal.fire({
          title: 'Επιτυχία',
          icon: 'success',
          text: response.data.message,
          allowEnterKey: true,
        });
        this.table.ajax.reload(null, false)
        this.modalRef.hide();
      }).catch((error) => ErrorHandle(error));
    },
    edit(id) {
      if (!this.privileges.canEditContainers) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.close();
      this.resetModalInputs();
      this.$refs.modalTitle.innerHTML = '<i class="fas fa-edit me-1"></i> Διόρθωση στοιχείων δεξαμενής'
      this.$refs.inputName.classList.remove('d-none')
      this.$refs.inputCapacity.classList.remove('d-none')

      this.$refs.modalButton.innerHTML = ModalButtons.update(id)

      this.filterProducts('');

      axios.post(apiRoutes.containers.edit, {id: id}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          this.clearForm();
          this.form.name = response.data.name;
          this.form.capacity = response.data.capacity;
          this.form.product_id = {value: response.data.product_id, label: response.data.liquid_name}

          this.modalRef.show('containersModal');

        }
      }).catch((error) => ErrorHandle(error));
    },
    update(id) {
      if (!this.privileges.canUpdateContainers) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      const containerData = new FormData();
      containerData.append('id', parseInt(id));
      containerData.append('name', this.form.name);
      containerData.append('capacity', this.form.capacity);

      axios.patch(apiRoutes.containers.update, Object.fromEntries(containerData), {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        Swal.fire({
          title: 'Επιτυχία',
          icon: 'success',
          text: response.data.message,
          allowEnterKey: true,
        });
        this.table.ajax.reload(null, false)
        this.modalRef.hide();
      }).catch((error) => ErrorHandle(error));
    },
    destroy(id) {
      if (!this.privileges.canDestroyContainers) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      Swal.close();
      Swal.fire({
        title: 'Προσοχή',
        icon: 'warning',
        text: 'Αυτή η ενέργεια θα διαγράψει μόνιμα την εγγραφή! Θέλετε σίγουρα να προχωρήσετε;',
        confirmButtonText: 'Διαγραφή',
        confirmButtonColor: 'red',
        showCancelButton: true,
        cancelButtonText: 'Ακύρωση',
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'DELETE',
            url: apiRoutes.containers.destroy,
            data: {'id': id},
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
          }).then((response) => {
            Swal.fire({
              title: 'Επιτυχία',
              icon: 'success',
              text: response.data.message,
              allowEnterKey: true,
            })
            this.table.ajax.reload(null, false);
          }).catch((error) => ErrorHandle(error))
        }
      });
    },
    filterProducts(value) {
      if (!this.privileges.canFilterProducts) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      axios.post(apiRoutes.products.filter, {'name': value}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        this.products = [];
        response.data.forEach((item) => {
          this.products.push({value: item.id, label: item.name})
        })
      }).catch((error) => ErrorHandle(error));
    },
    filterBlendContainers(value) {
      if (!this.privileges.canFilterContainers) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      axios.post(apiRoutes.containers.filter, {
        type: 'blend',
        name: value,
        container_id: this.form.container_id,
        used_containers: this.form.containers,
      }, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        this.containers = [];
        response.data.forEach((item) => {
          this.containers.push({
            value: item.id,
            label: `${item.container_name} (${item.product_name}) (${item.level} / ${item.capacity})`,
            name: item.container_name
          })
        })
      }).catch(error => ErrorHandle(error));
    },
    filterTransferContainers(value) {
      if (!this.privileges.canFilterContainers) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      axios.post(apiRoutes.containers.filter, {
        type: 'transfer',
        name: value,
        container_id: this.form.container,
        product_id: this.form.product_id,
      }, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        this.containers = [];
        response.data.forEach((item) => {
          this.containers.push({
            value: item.id,
            label: `${item.container_name} (${item.product_name}) (${item.level} / ${item.capacity})`,
          })
        })
      }).catch(error => ErrorHandle(error));
    },
    clearForm() {
      this.form.name = '';
      this.form.capacity = 0;
      this.form.quantity = 0;
      this.form.waste = 0;
      this.form.notes = '';
      this.form.product_id = null;
      this.form.container_id = null;
      this.form.containers.forEach((container) => {
        if (container.quantity) {
          container.quantity = 0;
        }
      })
      this.form.containers = [];
    },
    selectContainer(action) {
      if (action === 'blend') {
        this.filterBlendContainers('');
        this.form.containers.forEach((container) => {
          if (container.quantity === undefined) {
            container.quantity = 0;
          }
          if (container.waste === undefined) {
            container.waste = 0;
          }
        })
      }
      if (action === 'transfer') {
        // this.filterTransferContainers('');
      }
    },
    selectProduct() {
      this.filterProducts('');
    },
    resetModalInputs() {
      this.$refs.inputContainers.classList.add('d-none')
      this.$refs.inputName.classList.add('d-none')
      this.$refs.inputCapacity.classList.add('d-none')
      this.$refs.inputProducts.classList.add('d-none')
      this.$refs.inputWaste.classList.add('d-none')
      this.$refs.inputNotes.classList.add('d-none')
      this.$refs.inputQuantity.classList.add('d-none')
      this.$refs.inputContainersBlend.classList.add('d-none')
    },
    validateDecimal(input) {
      input.value = input.value.replace(/,/g, '.');

      return parseFloat(input.value);
    },
    clickMobileContainer(id, container) {

      Swal.fire({
        allowHtml: true,
        title: 'ΕΠΙΛΟΓΕΣ ΔΕΞΑΜΕΝΗΣ',
        html: this.getActionButtons(id, container, true),
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
      });

    },
    handleEnterKey() {
      // Use nextTick to ensure the DOM is updated
      this.$nextTick(() => {
        // Access the button using $refs after the DOM update
        document.getElementById('modal-confirm-button').click();
      });
    }
  }
}
</script>

<style>
@media only screen and (max-width: 768px) {
  .dataTables_filter {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .dataTables_filter {
    margin-bottom: 20px;
  }
}

.custom-card {
  width: 100%; /* Adjust the width as needed */
  max-width: 350px; /* Maximum width if required */
  cursor: pointer;
}
</style>
