<template>
  <section class="h-100">
    <div class="container h-100">
      <div class="row justify-content-sm-center h-100">
        <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
          <div class="text-center my-5">
            <img src="@/assets/img/logo-500x500.png" alt="logo" width="100">
            <h1 class="text-white">Tankometric</h1>
          </div>
          <div class="card shadow-lg">
            <div class="card-body p-5">
              <h1 class="fs-4 card-title fw-bold mb-4">Σύνδεση</h1>
              <form method="POST" class="needs-validation" novalidate="" autocomplete="off">
                <div class="mb-3">
                  <label class="mb-2 text-muted" for="email">Διεύθυνση E-Mail</label>
                  <input @keydown.enter.prevent="login" id="email" type="email" class="form-control" name="email" value=""
                         v-model="form.email"
                         required autofocus>
                  <div class="invalid-feedback">
                    Email is invalid
                  </div>
                </div>

                <div class="mb-3">
                  <div class="mb-2 w-100">
                    <label class="text-muted" for="password">Κωδικός Πρόσβασης</label>
                  </div>
                  <input @keydown.enter.prevent="login" id="password" type="password" class="form-control" name="password"
                         v-model="form.password"
                         required>
                  <div class="invalid-feedback">
                    Password is required
                  </div>
                </div>

                <div class="d-grid gap-2 mx-auto">
                  <button @click="login" class="btn btn-primary" type="button">Σύνδεση</button>
                </div>
              </form>
            </div>
            <div class="card-footer text-center py-3">
              <div class="small"><router-link :to="{name: 'forgotPassword'}">Ξεχάσατε τον κωδικό σας;</router-link></div>
            </div>
          </div>
          <div class="text-center mt-5 text-muted">
            Copyright &copy; {{ date }} &mdash; Tankometric
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import errorHandle from "@/assets/js/Library/ErrorHandle";
import Swal from "sweetalert2";
import apiRoutes from "@/router/ApiRoutes";

export default {
  name: 'LoginView',
  meta: {guest: true},
  data() {
    return {
      dateStarted: 2024,
      date: null,
      form: {
        email: '',
        password: '',
      }
    }
  },
  mounted() {
    document.body.classList.add('bg-dark');

    const currentYear = new Date().getFullYear();

    this.date = currentYear;
    if (currentYear > this.dateStarted) {
      this.date = this.dateStarted + ' - ' + currentYear;
    }
  },
  methods: {
    login() {
      if (this.form.email && this.form.password) {
        axios.post(apiRoutes.login, {
          email: this.form.email,
          password: this.form.password,
        }, {
          withCredentials: true,
          headers: {Accept: 'application/json'}
        }).then((response) => {
          if (response.status === 200) {
            localStorage.clear();
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('username', response.data.username)
            localStorage.setItem('privilege', response.data.privilege)
            Swal.fire({
              title: 'Επιτυχία',
              icon: 'success',
              text: response.data.message,
              timer: 600,
              showConfirmButton: false,
              allowEnterKey: true,
            }).then(() => {
              this.$router.push({name: 'dashboard'});
            });

          }
        }).catch(error => errorHandle(error))
      }
    }
  },
}
</script>

<style scoped>
$dark: #212529l;
</style>