const apiPath = process.env.VUE_APP_API_URL;

const ApiRoutes = {
    login: apiPath + '/login',
    logout: apiPath + '/logout',
    resetPassword: apiPath + '/reset-password',
    forgotPassword: apiPath + '/forgot-password',
    checkPasswordRequest: apiPath + '/check-reset-password',
    containers: {
        datatable: apiPath + '/containers/get',
        store: apiPath + '/containers/store',
        edit: apiPath + '/containers/edit',
        update: apiPath + '/containers/update',
        destroy: apiPath + '/containers/destroy',
        filter: apiPath + '/containers/filter',
        chart: apiPath + '/containers/chart',
    },
    products: {
        datatable: apiPath + '/products/get',
        store: apiPath + '/products/store',
        edit: apiPath + '/products/edit',
        update: apiPath + '/products/update',
        destroy: apiPath + '/products/destroy',
        filter: apiPath + '/products/filter',
        chart: apiPath + '/products/chart',
    },
    liquids: {
        datatable: apiPath + '/liquids/get',
        store: apiPath + '/liquids/store',
        edit: apiPath + '/liquids/edit',
        update: apiPath + '/liquids/update',
        destroy: apiPath + '/liquids/destroy',
        filter: apiPath + '/liquids/filter',
    },
    transfers: {
        get: apiPath + '/transfers/get',
        view: apiPath + '/transfers/view',
    },
    logs: {
        get: apiPath + '/logs/get',
        view: apiPath + '/logs/view',
    },
    actions: {
        add: apiPath + '/actions/add',
        transfer: apiPath + '/actions/transfer',
        bottling: apiPath + '/actions/bottling',
        blend: apiPath + '/actions/blend',
    },
    admins: {
        datatable: apiPath + '/admins/get',
        store: apiPath + '/admins/store',
        edit: apiPath + '/admins/edit',
        update: apiPath + '/admins/update',
        destroy: apiPath + '/admins/destroy',
    }

}
export default ApiRoutes;