const Privileges = {
    CAN_SEE_CONTAINERS_DATATABLE: BigInt(1),
    CAN_STORE_CONTAINERS: BigInt(2),
    CAN_EDIT_CONTAINERS: BigInt(4),
    CAN_UPDATE_CONTAINERS: BigInt(8),
    CAN_DESTROY_CONTAINERS: BigInt(16),
    CAN_FILTER_CONTAINERS: BigInt(32),
    CAN_SEE_CONTAINERS_CHART: BigInt(64),
    CAN_SEE_PRODUCTS_DATATABLE: BigInt(128),
    CAN_STORE_PRODUCTS: BigInt(256),
    CAN_EDIT_PRODUCTS: BigInt(512),
    CAN_UPDATE_PRODUCTS: BigInt(1024),
    CAN_DESTROY_PRODUCTS: BigInt(2048),
    CAN_FILTER_PRODUCTS: BigInt(4096),
    CAN_SEE_PRODUCTS_CHART: BigInt(8192),
    CAN_SEE_LIQUIDS_DATATABLE: BigInt(16384),
    CAN_STORE_LIQUIDS: BigInt(32768),
    CAN_EDIT_LIQUIDS: BigInt(65536),
    CAN_UPDATE_LIQUIDS: BigInt(131072),
    CAN_DESTROY_LIQUIDS: BigInt(262144),
    CAN_FILTER_LIQUIDS: BigInt(524288),
    CAN_SEE_TRANSFERS_DATATABLE: BigInt(1048576),
    CAN_SEE_TRANSFERS_INFO: BigInt(2097152),
    CAN_SEE_LOGS_DATATABLE: BigInt(4194304),
    CAN_SEE_LOGS_INFO: BigInt(8388608),
    CAN_ADD_PRODUCT_TO_CONTAINER: BigInt(16777216),
    CAN_TRANSFER_PRODUCT_BETWEEN_CONTAINERS: BigInt(33554432),
    CAN_BOTTLE_PRODUCT: BigInt(67108864),
    CAN_BLEND_PRODUCT: BigInt(134217728),
    CAN_SEE_ADMINS_DATATABLE: BigInt(268435456),
    CAN_STORE_ADMINS: BigInt(536870912),
    CAN_EDIT_ADMINS: BigInt(1073741824),
    CAN_UPDATE_ADMINS: BigInt(2147483648),
    CAN_DESTROY_ADMINS: BigInt(4294967296),
};

export default Privileges