<template>
  <div class="row mb-2">
    <div class="col-1">
      <select class="form-control form-select" v-model="table.perPage" @change="setPerPage($event.target.value)">
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
    <div class="col-9"></div>
    <div class="col-2">
      <input type="text" class="form-control" placeholder="Αναζήτηση..." v-model="table.search" @input="fetchData">
    </div>
  </div>

  <table class="table">
    <thead>
    <tr>
      <th scope="col" @click="sort('name', (table.sort_dir === 'asc' ? 'desc' : 'asc'))">
        Όνομα
        <span v-if="(table.sort_by === 'name' && table.sort_dir === 'desc')">
          <i class="fas fa-arrow-down-long ms-1"></i>
        </span>
        <span v-else-if="(table.sort_by === 'name' && table.sort_dir === 'asc')">
          <i class="fas fa-arrow-up-long ms-1"></i>
        </span>
        <span v-else>
          <i class="fas fa-arrow-down-long ms-1"></i>
          <i class="fas fa-arrow-up-long"></i>
        </span>
      </th>
      <th scope="col" @click="sort('surname', (table.sort_dir === 'asc' ? 'desc' : 'asc'))">
        Επώνυμο
        <span v-if="(table.sort_by === 'surname' && table.sort_dir === 'desc')">
          <i class="fas fa-arrow-down-long ms-1"></i>
        </span>
        <span v-else-if="(table.sort_by === 'surname' && table.sort_dir === 'asc')">
          <i class="fas fa-arrow-up-long ms-1"></i>
        </span>
        <span v-else>
          <i class="fas fa-arrow-down-long ms-1"></i>
          <i class="fas fa-arrow-up-long"></i>
        </span>
      </th>
      <th scope="col" @click="sort('email', (table.sort_dir === 'asc' ? 'desc' : 'asc'))">
        E-mail
        <span v-if="(table.sort_by === 'email' && table.sort_dir === 'desc')">
          <i class="fas fa-arrow-down-long ms-1"></i>
        </span>
        <span v-else-if="(table.sort_by === 'email' && table.sort_dir === 'asc')">
          <i class="fas fa-arrow-up-long ms-1"></i>
        </span>
        <span v-else>
          <i class="fas fa-arrow-down-long ms-1"></i>
          <i class="fas fa-arrow-up-long"></i>
        </span>
      </th>
      <th class="text-center" scope="col">Ενέργειες</th>
    </tr>
    </thead>
    <tbody>
    <tr v-if="admins.length === 0">
      <td colspan="4" class="text-center">Δεν βρέθηκαν εγγραφές!</td>
    </tr>
    <tr v-for="admin in admins" :key="admin.id">
      <td>{{ admin.name }}</td>
      <td>{{ admin.surname }}</td>
      <td>{{ admin.email }}</td>
      <td class="text-center">
        <span v-html="getActionButtons(admin.id)"></span>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="row">
    <div class="col">
      <span v-if="admins.length > 0">Προβολή σελίδας {{ paging.current_page }} από {{ paging.last_page }} σελίδες συνολικά</span>
      <span v-if="table.search">(μέσα από έρευνα σε {{ paging.totalRecords }} συνολικά εγγραφές)</span>
    </div>
    <div class="col d-flex justify-content-end">
      <paginator-component :total-pages="paging.last_page" :current-page="paging.current_page"
                           @page-changed="changePage"/>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import ApiRoutes from "@/router/ApiRoutes";
import PaginatorComponent from "@/components/PaginatorComponent.vue";
import Privileges from "@/assets/js/Library/Privileges";
import AdminsModal from "@/views/Admins/AdminsModal.vue";

export default {
  name: 'AdminsTable',
  components: {AdminsModal, PaginatorComponent},
  data() {
    return {
      privileges: {
        canStoreAdmins: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_STORE_ADMINS) === Privileges.CAN_STORE_ADMINS,
        canEditAdmins: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_EDIT_ADMINS) === Privileges.CAN_EDIT_ADMINS,
        canUpdateAdmins: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_UPDATE_ADMINS) === Privileges.CAN_UPDATE_ADMINS,
        canDestroyAdmins: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_DESTROY_ADMINS) === Privileges.CAN_DESTROY_ADMINS,
      },
      admins: [],
      paging: {
        from: 1,
        to: 10,

        current_page: 1,
        last_page: 1,

        per_page: 10,
        total: 0,

        totalRecords: 0
      },
      table: {
        search: '',
        sort_by: 'name',
        sort_dir: 'asc',
        perPage: 10,
        page: 1,
        nameSearch: '',
        surnameSearch: '',
        emailSearch: '',
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let data = new FormData();
      data.append('search', this.table.search);
      data.append('sort_by', this.table.sort_by);
      data.append('sort_dir', this.table.sort_dir);
      data.append('perPage', this.table.perPage);
      data.append('page', this.table.page);
      data.append('nameSearch', this.table.nameSearch);
      data.append('surnameSearch', this.table.surnameSearch);
      data.append('emailSearch', this.table.emailSearch);
      axios.post(ApiRoutes.admins.datatable, data, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }).then((response) => {
        this.admins = response.data.results.data;
        this.paging.from = response.data.results.from;
        this.paging.to = response.data.results.to;
        this.paging.current_page = response.data.results.current_page;
        this.paging.last_page = response.data.results.last_page;
        this.paging.per_page = response.data.results.per_page;
        this.paging.total = response.data.results.total;
        this.paging.totalRecords = response.data.totalRecords;
      });
    },
    getActionButtons(id) {
      let dropdown = `<a data-id="${id}" class="nav-link dropdown-toggle" id="actions-dropdown-${id}" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-cogs fa-fw"></i></a>`;
      dropdown += `<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="actions-dropdown-${id}">`;
      if (this.privileges.canEditAdmins) {
        dropdown += `<li onclick="edit(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-edit"></i> Διόρθωση</a></li>`;
        dropdown += `<li onclick="resetPassword(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-edit"></i> Επαναφορά Κωδικού</a></li>`;
      }
      if (this.privileges.canDestroyAdmins) {
        dropdown += `<li><hr class="dropdown-divider" /></li>`;
        dropdown += `<li onclick="destroy(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-trash"></i> Διαγραφή</a></li>`;
      }
      dropdown += `</ul>`;
      return dropdown;
    },
    edit(id) {
      console.log(id)
      // axios.post(ApiRoutes.admins.edit, {id: id}, {
      //   headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      // }).then((response) => {
      //
      // })
    },
    setPerPage(value) {
      this.table.perPage = value;
      this.fetchData(); // Call method to fetch data with new perPage value
    },
    changePage(page) {
      this.table.page = page;
      this.fetchData();
    },
    sort(field, dir) {
      this.table.sort_by = field;
      this.table.sort_dir = dir;
      this.fetchData();
    }
  }

}
</script>

<style>
th {
  cursor: pointer;
}
</style>
