<template>
  <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
    <!-- Navbar Brand-->
    <router-link :to="{name: 'dashboard'}" class="navbar-brand ps-3">
      <img src="../assets/img/logo-30x30.png" alt="" class="img-fluid me-2 mb-1">Tankometric
      <sup><i>v.1.2</i></sup>
    </router-link>
    <!-- Sidebar Toggle-->
    <button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="javascript:void(0)"><i
        class="fas fa-bars"></i></button>
    <!-- Navbar User-->
    <div class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
      <h6 class="navbar-nav text-white-50">{{ username }}</h6>
    </div>
    <!-- Navbar-->
    <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown"
           aria-expanded="false"><i class="fas fa-user fa-fw"></i></a>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
<!--          <li><a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-user-gear me-1"></i> Προφίλ</a></li>-->
<!--          <li><a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-gear me-1"></i> Ρυθμίσεις</a></li>-->
<!--          <li>-->
<!--            <hr class="dropdown-divider"/>-->
<!--          </li>-->
          <li><a class="dropdown-item" href="javascript:void(0)" @click="prepareLogout">
            <i class="fa fa-right-from-bracket me-1"></i> Αποσύνδεση</a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>

  <!-- Logout Modal-->
  <div class="modal fade" ref="logoutModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Θέλετε σίγουρα να αποσυνδεθείτε από το CRM;</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Επιλέξτε "Αποσύνδεση" για άμεση έξοδο, ή "Ακύρωση" για επιστροφή στο CRM
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ακύρωση</button>
          <button type="button" class="btn btn-primary" @click="logout">Αποσύνδεση</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import $ from "jquery";
import bootstrap from 'bootstrap/dist/js/bootstrap.min';
import axios from "axios";
import errorHandle from "@/assets/js/Library/ErrorHandle";
import apiRoutes from "@/router/ApiRoutes";

export default {
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat('el-GR', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false
      }).format()
    }, 1000)
  },
  data() {
    return {
      interval: null,
      time: null,

      intervalId: null,
      lastActive: Date.now(),

      idleTime: 0,
      idleInterval: null,

      username: localStorage.getItem('username'),

      modalRef: null
    }
  },
  mounted() {
    const appDom = $('#app');
    this.modalRef = new bootstrap.Modal(this.$refs.logoutModal);

    this.intervalId = setInterval(() => {
      if (Date.now() - this.lastActive > 3600000) { // 60 Minutes
        clearInterval(this.intervalId);
        this.logout();
      }
    }, 1000);

    // Zero the idle timer on mouse movement.
    appDom.mousemove(() => {
      this.updateLastActive();
    });
    appDom.keypress(() => {
      this.updateLastActive();
    });
  },
  methods: {
    prepareLogout() {
      this.modalRef.show();
    },
    beforeUnmount() {
      clearInterval(this.intervalId);
    },
    logout() {
      axios.post(apiRoutes.logout, {}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          this.modalRef.hide();
          localStorage.clear();
          clearInterval(this.idleInterval);
          location.href = '/login';
        }
      }).catch((error) => errorHandle(error))

    },
    updateLastActive() {
      this.lastActive = Date.now();
    },
  }
}
</script>

<style scoped>

</style>