<template>
  <AdminLayout>
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i class="fas fa-wine-bottle me-2"></i>{{ menuTitles.liquids }}</h1>
      <ol class="breadcrumb mb-4">
        <router-link :to="{name: 'dashboard'}" class="breadcrumb-item"><i class="fas fa-house me-2"></i>Αρχική
        </router-link>
        <li class="breadcrumb-item active"><i class="fas fa-wine-bottle me-2"></i>{{ menuTitles.liquids }}</li>
      </ol>

      <div class="card mb-4">
        <div class="card-header">
          <div class="row">
            <div class="col float-start"><i class="fas fa-table me-1"></i>Πίνακας Διαχείρισης Συστατικών</div>
            <div class="col" v-if="privileges.canStoreLiquids">
              <button class="btn btn-outline-dark float-end" @click="prepareStore">
                <i class="fas fa-plus me-2"></i>Προσθήκη Νέου
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table id="liquids-datatable" class="table table-striped">
            <thead>
            <tr>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-quote-left me-1"></i>
                  <span class="d-none d-md-table-cell">Ονομασία</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-gear me-1"></i>
                </div>
              </th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><i class="fas fa-gear me-1"></i></th>
            </tr>
            </tfoot>
            <tbody>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </AdminLayout>

  <div class="modal fade" ref="liquidsModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" ref="modalTitle"></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>

            <div class="mb-3">
              <label for="liquid-name" class="form-label">Ονομασία</label>
              <input @keydown.enter.prevent="handleEnterKey" type="text" class="form-control" id="liquid-name"
                     v-model="form.name">
            </div>

          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
            Ακύρωση
          </button>
          <div ref="modalButton"></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src

import AdminLayout from "@/layouts/AdminLayout.vue";

import 'datatables.net/js/jquery.dataTables.min';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-bs5/js/dataTables.bootstrap5.min.js';

import $ from "jquery";

import bootstrap from 'bootstrap/dist/js/bootstrap.min';

import {DatatableLanguages} from "@/assets/js/Library/Datatables";
import ModalButtons from "@/assets/js/Library/Modals";
import axios from "axios";
import Swal from "sweetalert2";
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import menuTitles from "../assets/js/Library/MenuTitles";
import apiRoutes from "@/router/ApiRoutes";
import errorHandle from "@/assets/js/Library/ErrorHandle";
import Privileges from "@/assets/js/Library/Privileges";

export default {
  name: 'LiquidsView',
  computed: {
    menuTitles() {
      return menuTitles
    }
  },
  components: {AdminLayout},
  created() {
    window.store = this.store;
    window.edit = this.edit;
    window.update = this.update;
    window.destroy = this.destroy;
  },
  data() {
    return {
      privileges: {
        canStoreLiquids: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_STORE_LIQUIDS) === Privileges.CAN_STORE_LIQUIDS,
        canEditLiquids: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_EDIT_LIQUIDS) === Privileges.CAN_EDIT_LIQUIDS,
        canUpdateLiquids: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_UPDATE_LIQUIDS) === Privileges.CAN_UPDATE_LIQUIDS,
        canDestroyLiquids: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_DESTROY_LIQUIDS) === Privileges.CAN_DESTROY_LIQUIDS,
      },
      datatable: null,
      modalRef: null,
      form: {
        name: '',
      }
    }
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.liquidsModal, {
      backdrop: 'static',
      keyboard: false
    })

    this.datatable = $('#liquids-datatable').DataTable({
      initComplete: function () {
        this.api()
            .columns()
            .every(function () {
              const that = this;

              $('input', this.footer()).on('keyup change clear', function () {
                if (that.search() !== this.value) {
                  that.search(this.value).draw();
                }
              });
            });
      },
      language: DatatableLanguages,
      columnDefs: [
        {data: 'name', orderable: true, className: 'dt-center', targets: 0, width: 'auto'},
        {
          data: null,
          render: (data) => this.getActionButtons(data.id),
          orderable: false,
          className: `${this.privileges.canEditLiquids || this.privileges.canUpdateLiquids || this.privileges.canDestroyLiquids ? 'dt-center' : 'd-none'}`,
          targets: 1,
          width: 'auto'
        }
      ],
      autoWidth: false,
      processing: true,
      serverSide: true,
      ajax: {
        type: 'POST',
        url: apiRoutes.liquids.datatable,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Accept: 'application/json'
        },
        error: (error) => errorHandle(error)
      },
    });
  },
  methods: {
    getActionButtons(id) {
      let dropdown = `<a data-id="${id}" class="nav-link dropdown-toggle" id="actions-dropdown-${id}" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-cogs fa-fw"></i></a>`;
      dropdown += `<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="actions-dropdown-${id}">`;
      if (this.privileges.canEditLiquids) {
        dropdown += `<li onclick="edit(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-edit"></i> Διόρθωση</a></li>`;
      }
      if (this.privileges.canDestroyLiquids) {
        dropdown += `<li><hr class="dropdown-divider" /></li>`;
        dropdown += `<li onclick="destroy(${id})"><a class="dropdown-item" href="javascript:void(0)"><i class="fas fa-trash"></i> Διαγραφή</a></li>`;
      }
      dropdown += `</ul>`;
      return dropdown;
    },
    prepareStore() {
      if (!this.privileges.canStoreLiquids) {
        return;
      }
      this.$refs.modalTitle.innerHTML = '<i class="fas fa-wine-bottle me-1"></i> Δημιουργία Νέου Συστατικού'

      this.$refs.modalButton.innerHTML = ModalButtons.create

      this.clearForm();

      this.modalRef.show();
    },
    store() {
      if (!this.privileges.canStoreLiquids) {
        return;
      }
      axios.post(apiRoutes.liquids.store, {name: this.form.name}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          });
        }
        this.datatable.ajax.reload(null, false)
        this.modalRef.hide();
      }).catch((error) => ErrorHandle(error));
    },
    edit(id) {
      if (!this.privileges.canEditLiquids) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      this.$refs.modalTitle.innerHTML = '<i class="fas fa-edit me-1"></i> Διόρθωση Στοιχείων Συστατικού'
      this.$refs.modalButton.innerHTML = ModalButtons.update(id)

      axios.post(apiRoutes.liquids.edit, {id: id}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          this.clearForm();
          this.form.name = response.data.name;

          this.modalRef.show();

        }
      }).catch((error) => ErrorHandle(error));

    },
    update(id) {
      if (!this.privileges.canUpdateLiquids) {
        ErrorHandle({error: {response: {status: 401}}});
        return;
      }
      const liquidData = {
        id: parseInt(id),
        name: this.form.name,
      };

      axios.patch(apiRoutes.liquids.update, liquidData, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: 'Επιτυχία',
            icon: 'success',
            text: response.data.message,
            allowEnterKey: true,
          });
        }
        this.datatable.ajax.reload(null, false)
        this.modalRef.hide();
      }).catch((error) => ErrorHandle(error));
    },
    destroy(id) {
      if (!this.privileges.canDestroyLiquids) {
        return;
      }
      Swal.fire({
        title: 'Προσοχή',
        icon: 'warning',
        text: 'Αυτή η ενέργεια θα διαγράψει μόνιμα την εγγραφή! Θέλετε σίγουρα να προχωρήσετε;',
        confirmButtonText: 'Διαγραφή',
        confirmButtonColor: 'red',
        showCancelButton: true,
        cancelButtonText: 'Ακύρωση',
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'DELETE',
            url: apiRoutes.liquids.destroy,
            data: {'id': id},
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`, Accept: 'application/json'},
          }).then((response) => {
            Swal.fire({
              title: 'Επιτυχία',
              icon: 'success',
              text: response.data.message,
              allowEnterKey: true,
            })
            this.datatable.ajax.reload(null, false);
          }).catch(error => ErrorHandle(error))
        }
      });
    },
    clearForm() {
      this.form.name = '';
      this.form.consists = [];
    },
    handleEnterKey() {
      // Use nextTick to ensure the DOM is updated
      this.$nextTick(() => {
        // Access the button using $refs after the DOM update
        document.getElementById('modal-confirm-button').click();
      });
    }
  }
}
</script>
