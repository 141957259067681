const menuTitles = {
    dashboard: 'Αρχική',
    containers: 'Δεξαμενές',
    products: 'Προϊόντα',
    liquids: 'Συστατικά',
    transfers: 'Κινήσεις',
    logs: 'Αναφορές',
    admins: 'Διαχειριστές',
};

export default menuTitles;