import Swal from "sweetalert2";
import router from "@/router";
import {ref} from "vue";

export const DatatableLanguages = {
    lengthMenu: "Προβολή _MENU_ εγγραφών ανά σελίδα",
    zeroRecords: "Δεν βρέθηκαν εγγραφές!",
    info: "Προβολή σελίδας _PAGE_ από _PAGES_ σελίδες συνολικά",
    infoEmpty: "Δεν βρέθηκαν εγγραφές!",
    infoFiltered: "(μέσα από έρευνα σε _MAX_ συνολικά εγγραφές)",
    decimal: ",",
    thousands: ".",
    search: "Αναζήτηση",
    loadingRecords: "Φόρτωση δεδομένων...",
    processing: "Επεξεργασία...",
    paginate: {
        first: "&laquo;",
        last: "&raquo;",
        next: "&rsaquo;",
        previous: "&lsaquo;",
    },
};

export const DatatableErrorHandle = (error) => {
    if (error.status === 401 || error.status === 403) {
        Swal.fire({
            title: 'Σφάλμα Διαπιστευτηρίων',
            icon: 'error',
            text: 'Δεν έχετε τα απαραίτητα δικαιώματα για να εκτελέσετε αυτήν την ενέργεια. Πραγματοποιείται αυτόματη αποσύνδεση από το σύστημα.',
            timer: 2000,
            showConfirmButton: false
        }).then(() => {
            localStorage.clear();
            location.href = '/login';
        });
        return;
    }

    Swal.fire({
        title: 'Πρόβλημα στο server',
        icon: 'error',
        text: 'Προέκυψε ένα απροσδιόριστο πρόβλημα στον server. Παρακαλούμε συνδεθείτε ξανά στο σύστημα. Ζητούμε συγνώμη για την αναστάτωση.'
    }).then(() => {
        localStorage.clear();
        location.href = '/login';
    });
}