<template>
  <AdminLayout>
    <div class="container-fluid px-4">
      <h1 class="mt-4"><i class="fas fa-clipboard-list me-2"></i>Αναφορές</h1>
      <ol class="breadcrumb mb-4">
        <router-link :to="{name: 'dashboard'}" class="breadcrumb-item"><i class="fas fa-house me-2"></i>Αρχική
        </router-link>
        <li class="breadcrumb-item active"><i class="fas fa-clipboard-list me-2"></i>Αναφορές</li>
      </ol>

      <div class="card mb-4">
        <div class="card-header">
          <div class="row">
            <div class="col float-start"><i class="fas fa-table me-1"></i>Πίνακας κινήσεων</div>
          </div>
          <div class="row">
            <div class="col float-start">
              <div class="d-none d-md-flex">
                Κινήσεις:
                <a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(0)"><i class="badge rounded-pill bg-black">Όλες</i></span></a>
                <a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(1)"><i class="badge rounded-pill bg-danger">Είσοδος</i></span></a>
                <a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(2)"><i class="badge rounded-pill bg-primary">Γέμισμα</i></span></a>
                <a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(3)"><i class="badge rounded-pill bg-success">Εμφιάλωση</i></span></a>
                <a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(4)"><i class="badge rounded-pill bg-info">Μεταφορά</i></span></a>
              </div>
              <div class="d-flex d-md-none">
                <a class="nav-link dropdown-toggle" id="typeOfMoveDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Κινήσεις</a>
                <ul class="dropdown-menu" aria-labelledby="typeOfMoveDropdown">
                  <li><a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(0)"><i class="badge rounded-pill bg-black">Όλες</i></span></a></li>
                  <li><a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(1)"><i class="badge rounded-pill bg-danger">Είσοδος</i></span></a></li>
                  <li><a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(2)"><i class="badge rounded-pill bg-primary">Γέμισμα</i></span></a></li>
                  <li><a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(3)"><i class="badge rounded-pill bg-success">Εμφιάλωση</i></span></a></li>
                  <li><a href="javascript:void(0)"><span class="m-1" @click="filterTableByType(4)"><i class="badge rounded-pill bg-info">Μεταφορά</i></span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table id="logs-datatable" class="table table-striped">
            <thead>
            <tr>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-right-left me-1" title="Ενέργεια"></i>
                  <span class="d-none d-md-table-cell">Ενέργεια</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-calendar me-1" title="Ημερομηνία"></i>
                  <span class="d-none d-md-table-cell">Ημερομηνία</span>
                </div>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-gear me-1" title="Ενέργειες"></i>
                </div>
              </th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..." v-model="tableInputs.type"></th>
              <th>
                <VueDatePicker
                    ref="datepicker"
                    v-model="date"
                    locale="el"
                    cancelText="Ακύρωση"
                    selectText="Αναζήτηση"
                    placeholder="Ημερομηνία"
                    range
                    :enable-time-picker="false"
                    @update:model-value="handleDate"
                ></VueDatePicker>
              </th>
              <th>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fas fa-gear me-1" title="Ενέργειες"></i>
                </div>
              </th>
            </tr>
            </tfoot>
            <tbody>

            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="modal fade" ref="LogsModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" ref="modalTitle"><i class="fas fa-clipboard-list me-1"></i>Αναλυτικά στοιχεία αναφοράς</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>

              <div class="mb-3">
                <span class="me-2"><b>Ενέργεια:</b></span>
                <span><i ref="formLogType"></i></span>
              </div>

              <div class="mb-3">
                <span class="me-2"><b>Ημερομηνία:</b></span>
                <span><i ref="formDate"></i></span>
              </div>

              <div class="mb-3">
                <span class="me-2"><b>Λεπτομέρειες:</b></span>
                <span><i ref="formDetails"></i></span>
              </div>

              <div class="mb-3">
                <span class="me-2"><b>Σημειώσεις:</b></span>
                <span><i ref="formNotes"></i></span>
              </div>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-xmark me-1"></i>
              Κλείσιμο
            </button>
            <div ref="modalButton"></div>
          </div>
        </div>
      </div>
    </div>

  </AdminLayout>
</template>

<script>
// @ is an alias to /src

import AdminLayout from "@/layouts/AdminLayout.vue";
import $ from "jquery";
import {DatatableLanguages} from "@/assets/js/Library/Datatables";

import bootstrap from 'bootstrap/dist/js/bootstrap.min';

import 'datatables.net/js/jquery.dataTables.min';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-bs5/js/dataTables.bootstrap5.min.js';
import VueDatePicker from '@vuepic/vue-datepicker';
import axios from "axios";
import apiRoutes from "@/router/ApiRoutes";
import ErrorHandle from "@/assets/js/Library/ErrorHandle";
import {DatatableErrorHandle} from "@/assets/js/Library/Datatables";
import Privileges from "@/assets/js/Library/Privileges";

export default {
  name: 'ContainersView',
  components: {AdminLayout, VueDatePicker},
  created() {
    window.info = this.info;
  },
  data() {
    return {
      privileges: {
        canSeeLogsInfo: (BigInt(localStorage.getItem('privilege')) & Privileges.CAN_SEE_LOGS_INFO) === Privileges.CAN_SEE_LOGS_INFO,
      },
      datatable: null,
      modalRef: null,
      datePickerRef: null,
      date: null,
      tableInputs: {
        type: '',
        date: '',
      }
    }
  },
  mounted() {
    this.modalRef = new bootstrap.Modal(this.$refs.LogsModal)

    this.datePickerRef = this.$refs.datepicker;

    this.datatable = $('#logs-datatable').DataTable({
      initComplete: function () {
        this.api()
            .columns()
            .every(function () {
              const that = this;

              $('input', this.footer()).on('keyup change clear', function () {
                if (that.search() !== this.value) {
                  that.search(this.value).draw();
                }
              });
            });
      },
      responsive: true,
      language: DatatableLanguages,
      columnDefs: [
        {
          data: 'log_type_name',
          render: (data, type, row) => this.getTypeOfMove(row.log_type, data),
          orderable: true,
          className: 'dt-center',
          targets: 0,
          width: 'auto'
        },
        {
          data: 'created_at',
          render: (data, type, row) => this.getFormattedDate(row.created_date, row.created_time),
          orderable: true,
          className: 'dt-center',
          targets: 1,
          width: 'auto'
        },
        {
          data: null,
          render: (data) => this.getActionButtons(data.id),
          orderable: false,
          className: 'dt-center',
          targets: 2,
          width: '10%'
        }
      ],
      autoWidth: false,
      processing: true,
      serverSide: true,
      order: [[1, 'desc']],
      ajax: {
        type: 'POST',
        url: apiRoutes.logs.get,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          Accept: 'application/json'
        },
        error: (error) => DatatableErrorHandle(error)
      },
    });
  },
  methods: {
    getActionButtons(id) {
      return `<button class="btn btn-sm btn-outline-dark" onclick="info(${id})"><i class="fas fa-info-circle"></i></button>`
    },
    getFormattedDate(date, time) {
      return `<i class="fas fa-calendar me-1"></i>${date} <i class="fas fa-clock ms-1 me-1"></i>${time}`;
    },
    getTypeOfMove(id, type) {
      let color = '';
      if (id === 1) {
        color = 'danger';
      }
      if (id === 2) {
        color = 'primary';
      }
      if (id === 3) {
        color = 'success';
      }
      if (id === 4) {
        color = 'info';
      }

      return `<span class="badge rounded-pill bg-${color}">${type}</span>`;
    },

    info(id) {
      if (!this.privileges.canSeeLogsInfo) {
        return;
      }
      axios.post(apiRoutes.logs.view, {id: id}, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }).then((response) => {
        this.$refs.formDate.innerHTML = `<i><i class="fas fa-regular fa-calendar ms-2"></i> ${response.data.created_date} <i class="fas fa-regular fa-clock ms-2"></i> ${response.data.created_time}</i>`;
        this.$refs.formLogType.innerHTML = this.getTypeOfMove(response.data.log_type, response.data.log_type_name);
        this.$refs.formNotes.innerHTML = response.data.notes ? response.data.notes : '-' ;
        this.$refs.formDetails.innerHTML = response.data.details ? response.data.details : '-' ;

        this.modalRef.show();
      }).catch((error) => ErrorHandle(error))
    },
    handleDate(value) {
      if (value && value[0] && value[1] && this.datatable.search() !== value) {
        const formattedDate = [value[0].toLocaleDateString(), value[1].toLocaleDateString()]
        this.datatable.columns().column(1).search(formattedDate).draw();
        return;
      }
      this.datatable.columns().column(1).search('').draw();

    },
    filterTableByType(type) {
      let value = '';
      if (type === 1) {
        value = 'Είσοδος'
      }
      if (type === 2) {
        value = 'Γέμισμα'
      }
      if (type === 3) {
        value = 'Εμφιάλωση'
      }
      if (type === 4) {
        value = 'Μεταφορά'
      }

      this.tableInputs.type = value;
      this.datatable.columns().column(0).search(value).draw();
    }
  }
}
</script>
